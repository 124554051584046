import {Component, Output, EventEmitter, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit{
  @Input() showToggle = true;
  @Input() showUser = true;
  @Input() showHeader = true;
  @Input() toggleChecked = false;
  @Output() toggleCollapsed = new EventEmitter<void>();

  year: number;
  companyPathUrlInterferentia = 'https://www.interferentia.it/';
  companyPathUrlBemart = 'https://www.bemart.it/';

  constructor() {}

  homeSite(company) {
    let url = "";
    switch (company) {
      case "interferentia":
        url = this.companyPathUrlInterferentia;
        break;
      case "bemart":
        url = this.companyPathUrlBemart;
        break;
      default:
        break;
    }
    window.open(url, '_blank');
  }

  ngOnInit() {
    this.year = new Date().getFullYear();
  }
}
