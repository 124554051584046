import { Component, OnInit, AfterViewInit } from '@angular/core';
import { PreloaderService } from '@core';

@Component({
  selector: 'app-root',
  template: `
    <!-- routes will be rendered here -->
    <router-outlet></router-outlet>

    <!-- footer -->
<!--    <app-footer></app-footer>-->
  `,
  styles: [],
})
export class AppComponent implements OnInit, AfterViewInit {
  title: 'brdt-web-portal';
  constructor(private preloader: PreloaderService) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.preloader.hide();
  }
}
