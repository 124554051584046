<div class="w-full h-full" fxLayout="row wrap" fxLayoutAlign="center center">
  <mat-card class="mat-elevation-z4 m-b-0" style="max-width: 380px;">
    <div style="text-align: center;">
    <img
      class="matero-user-panel-avatar"
      src="assets/images/avatarAdmin.png"
      alt="avatar"
      width="44"
    />
    </div>
    <mat-card-title class="text-center m-b-24">{{'login.title' | translate}}!</mat-card-title>
    <mat-card-content>
      <form class="form-field-full" [formGroup]="loginForm">

        <mat-form-field appearance="outline">
          <mat-label>{{'login.username' | translate}}</mat-label>
          <input matInput placeholder="" formControlName="username" required>
          <mat-error *ngIf="username.invalid">
            {{'login.please-enter' | translate}} correct username
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{'login.password' | translate}}</mat-label>
          <input matInput placeholder="" type="password"
                 formControlName="password" required>
          <mat-error *ngIf="password.invalid">
            {{'login.please-enter' | translate}} correct password
          </mat-error>
        </mat-form-field>

        <div class="m-y-16" fxLayout="row" fxLayoutAlign="space-between center">
          <mat-checkbox>{{'login.remember-me' | translate}}</mat-checkbox>
        </div>

        <button class="w-full" mat-raised-button color="accent"
                [disabled]="loginForm.invalid"
                (click)="login()">{{'login.login' | translate}}</button>

<!--        <div class="m-t-16">{{'login.have-no-account' | translate}}?-->
<!--          <a routerLink="/auth/register">{{'login.create-one' | translate}}</a>-->
<!--        </div>-->
      </form>
    </mat-card-content>
  </mat-card>
</div>
