<breadcrumb></breadcrumb>
<div>
  <mat-card-title>
    BRDT_WSC Dashboard
  </mat-card-title>
  <mat-divider [inset]="false"></mat-divider>
</div>
<br>
<mat-progress-bar mode="indeterminate" color="accent" *ngIf="bar"></mat-progress-bar>

<div class="matero-row" fxLayout="row wrap" [hidden]="!bar">

  <!-- Statistics -->
  <div class="matero-col" fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100"
       *ngFor="let stat of stats">
    <mat-card [ngClass]="['text-white', stat.color]">
      <mat-card-title class="f-s-12 f-w-100">{{stat.title}}</mat-card-title>
      <mat-card-content>
        <h2>{{stat.amount}}</h2>
        <mtx-progress [value]="stat.progress.value"
                      height="2px"
                      foreground="rgba(255,255,255,1)"
                      background='rgba(0,0,0,.5)'>
        </mtx-progress>
        <small class="text-muted"></small>
      </mat-card-content>
    </mat-card>
  </div>

  <!--   Chart-->
  <div class="matero-col" fxFlex="100" fxFlex.gt-sm="60" [hidden]="bar">
    <mat-card>
      <mat-card-subtitle>{{'others.userDailyOverview' | translate}}
      </mat-card-subtitle>
      <div id="chart1"></div>
    </mat-card>
  </div>

  <!-- Transfers table -->
  <div class="matero-col" fxFlex="100" fxFlex.gt-sm="40" [hidden]="bar">
    <mat-card>
      <mat-card-subtitle>{{'tables.usersFileActions' | translate}}</mat-card-subtitle>
      <mat-card-content>
        <table class="w-full" mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> No.</th>
            <td mat-cell *matCellDef="let element;"> {{element.position}} </td>
          </ng-container>
          <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'tables.user' | translate}}</th>
            <td mat-cell *matCellDef="let element;"> {{element.userName}} </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'tables.actionType' | translate}}</th>
            <td mat-cell *matCellDef="let element;"> {{element.action}} </td>
          </ng-container>
          <ng-container matColumnDef="count">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'tables.count' | translate}}</th>
            <td mat-cell *matCellDef="let element;"> {{element.count}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- Bar chart -->
  <div class="matero-col" fxFlex="100" fxFlex.gt-sm="40" [hidden]="bar">
    <mat-card>
      <mat-card-subtitle>{{'others.deviceDistribution' | translate}}
      </mat-card-subtitle>
      <div id="barChart"></div>
    </mat-card>
  </div>

</div>
<div fxLayout="row wrap" class="matero-row">
  <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col">
    <mat-divider [inset]="false"></mat-divider>
    <small class="text-muted paddingFromDivider" *ngIf="updateTime">
      {{'others.lastUpdate' | translate}}: {{updateTime.format("DD.MM.YYYY HH:mm:ss")}}</small>
  </div>
</div>
