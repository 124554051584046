<div class="matero-sidebar-header" *ngIf="showHeader">
  <app-branding></app-branding>
  <span fxFlex></span>
  <!--  <mat-slide-toggle (change)="toggleCollapsed.emit()" [checked]="toggleChecked" *ngIf="showToggle">-->
  <!--  </mat-slide-toggle>-->
</div>

<div class="scrollbar-none">
  <div class="matero-sidebar-main scrollbar-none">
    <app-user-panel *ngIf="showUser"></app-user-panel>
    <app-sidemenu [ripple]="showToggle"></app-sidemenu>
  </div>
  <div class="stick-to-bottom matero-user-panel-name">
    <small class="text-muted paddingFromDivider">
      Licenced by <a href="#" (click)="homeSite('interferentia')" class="homeSite">Interferentia Srl</a><br>
      Enpowered by <a href="#" (click)="homeSite('bemart')" class="homeSite">Bemart Italia Srl</a></small>
  </div>
</div>
