import { Component, OnInit } from '@angular/core';
import {DeviceService} from './device.service';
import {UserManagementService} from '../userAndGroup/user-management/user-management.service';
import {GroupManagementService} from '../userAndGroup/group-management/group-management.service';
import {Device} from '../../model/device.model';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  providers: [DeviceService]
})
export class DevicesComponent implements OnInit {

  devicesList: Device[];

  constructor(private deviceService: DeviceService) { }

  ngOnInit(): void {
    this.findAll();
  }

  findAll(): void {
    this.deviceService.getDevices().subscribe((answer) => {
      this.devicesList = answer;
    });
  }
}
