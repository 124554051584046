import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import {DashboardService} from './dashboard.service';
import {ApiService} from '@shared/services/api.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import * as moment from 'moment';
import {Moment} from 'moment';
import {timer} from 'rxjs';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions,
  ApexStroke,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexFill,
  ApexLegend
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  fill: ApexFill;
  legend: ApexLegend;
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: [
      `
          .mat-raised-button {
              margin-right: 8px;
              margin-top: 8px;
          }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DashboardService, ApiService],
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  displayedColumns: string[] = ['position', 'userName', 'action', 'count'];
  dataSource = new MatTableDataSource(this.dashboardSrv.getData());

  // TODO: A great Live dashboard idea
  // https://apexcharts.com/javascript-chart-demos/dashboards/realtime/

  charts = this.dashboardSrv.getCharts();
  chart1 = null;
  chart = null;

  stats = [];

  hourlyUsers = new Map<string, number>();
  devicesMap = new Map<string, number>();

  updateTime: Moment;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  dashboardData: any;
  dashboardDataTiles = [];
  bar = true;
  updateInterval: any;

  constructor(
    private dashboardSrv: DashboardService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    const refreshTime = 30 * 1000;
    this.updateInterval = timer(0, refreshTime).subscribe(action => {
      this.dashboardSrv.getDashboardData().subscribe((answer) => {
        this.bar = false;
        this.dataSource.data = answer.userFilesCount; // = new MatTableDataSource(answer.userFilesCount);
        this.cdr.detectChanges();
        this.bar = true;
        this.updateTime = moment();
        if (this.chart1) {
          this.chart1.destroy();
        }
        if (this.chart) {
          this.chart.destroy();
        }
        this.initDashboard(answer);
      });
    });
  }

  /*
      const refreshTime = 30 * 1000;
      this.updateInterval = timer(0, refreshTime).subscribe(action => {
        this.findAll();
        this.updateTime = moment();
      });
   */

  ngAfterViewInit() {
    // this.ngZone.runOutsideAngular(() => this.initChart());
  }

  ngOnDestroy() {
    if (this.chart1) {
      this.chart1.destroy();
    }
    if (this.chart) {
      this.chart.destroy();
    }
    if (this.updateInterval) {
      this.updateInterval.unsubscribe();
    }
  }


  initDashboard(answer: any) {
    this.bar = false;
    this.dashboardData = answer;
    this.stats = this.dashboardSrv.getStats();
    this.stats = this.dashboardData.dashboardTileDTOList;
    this.dashboardDataTiles = this.dashboardData.dashboardTileDTOList;
    this.cdr.detectChanges();

    // HANDLE DEVICES
    this.devicesMap = new Map(Object.entries(answer.deviceCount));
    const device = [];
    const deviceCount = [];
    this.devicesMap.forEach((value, key) => {
      device.push(key);
      deviceCount.push(value);
    });

    // HANDLE USERS
    this.hourlyUsers = new Map(Object.entries(answer.usersPerHour));
    let userList = [];
    this.hourlyUsers.forEach((value, key) => {
      userList.push({date: moment(key, 'YYYY-MM-DD'), amount: value});
    });

    userList.sort((a, b) => {
      if (moment(a.date).isAfter(moment(b.date))) {
        return 1;
      } else if (moment(b.date).isAfter(moment(a.date))) {
        return -1;
      } else {
        return 0;
      }
    });

    userList.forEach((value, index, array) => {
      if (index === 0) {
        value.cumulativeSum = value.amount;
      } else {
        value.cumulativeSum = value.amount + array[index - 1].cumulativeSum;
      }
    });
    if (userList.length > 7) {
      userList = userList.slice(userList.length - 7, userList.length);
    }

    // SET DATA FOR LINE CHART
    this.charts[0].series[0].data = userList.map(element => element.amount);
    this.charts[0].series[1].data = userList.map(element => element.cumulativeSum);
    this.charts[0].xaxis.categories = userList.map(element => element.date.format('YYYY-MM-DD'));
    // DRAW LINE CHART
    this.chart1 = new ApexCharts(document.querySelector('#chart1'), this.charts[0]);
    this.chart1.render();


    const listOfDevices = [];
    this.devicesMap.forEach((value, key) => {
      listOfDevices.push({name: key, data: [value]});
    });

    this.charts[1].series = listOfDevices;

    this.chart = new ApexCharts(document.querySelector('#barChart'), this.charts[1]);
    this.chart.render();
  }
}
