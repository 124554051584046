import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SettingsService} from '@core';

@Component({
  selector: 'app-translate',
  template: `
    <button mat-icon-button class="matero-toolbar-button" [matMenuTriggerFor]="menu" title="{{'others.chooseLanguage' | translate}}">
      <mat-icon>language</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngFor="let lang of langs | keyvalue" (click)="useLanguage(lang.key)">
        <img src="./assets/images/english.png" class="flag mr-16" alt="Alosys logo" *ngIf="lang.value === 'English' "/>
        <img src="./assets/images/italian.png" class="flag mr-16" alt="Alosys logo" *ngIf="lang.value === 'Italian' "/>
        &nbsp;<span>{{ lang.value }}</span>
      </button>
    </mat-menu>
  `,
  styles: [],
})
export class TranslateComponent {
  langs = {
    'en-US': 'English',
    'it-IT': 'Italian',
  };

  constructor(private _translate: TranslateService, private _settings: SettingsService) {
    _translate.addLangs(['en-US', 'it-IT']);
    _translate.setDefaultLang('en-US');

    const browserLang = navigator.language;
    _translate.use(browserLang.match(/en-US|it-IT/) ? browserLang : 'en-US');
  }

  useLanguage(language: string) {
    this._translate.use(language);
    this._settings.setLanguage(language);
  }
}
