import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';


export interface IUserFilesCount {
  position: number;
  userName: string;
  action: string;
  count: number;
}

const iUserFilesCount: IUserFilesCount[] = [];

@Injectable()
export class DashboardService {

  constructor(private http: HttpClient,
              private translate: TranslateService) {
    this.charts[1].tooltip.x.formatter = this.charts[1].tooltip.x.formatter.bind(this);
  }


  private protocol = environment.protocol;
  private urlIpAddress = environment.SERVER_URL;
  private urlPort = environment.urlPort;
  private baseHref = environment.baseHref;
  url = this.protocol + '://' + this.urlIpAddress + ':' + this.urlPort;

//  logUrl = this.url + '/brdt/api/logs';
  dashboardUrl = this.url + this.baseHref + 'dashboard';

  ctx = 'myChart';

  statsFromBackEnd = [];

  stats = [
    {
      title: 'Total Users',
      amount: '0',
      progress: {
        value: 0,
      },
      color: 'bg-indigo-500',
    },
    {
      title: 'Users Off',
      amount: '0',
      progress: {
        value: 0,
      },
      color: 'bg-blue-500',
    },
    {
      title: 'Total Files',
      amount: '0',
      progress: {
        value: 0,
      },
      color: 'bg-green-500',
    },
    {
      title: 'Transfers',
      amount: '0',
      progress: {
        value: 0,
      },
      color: 'bg-teal-500',
    },
  ];

  charts = [
    {
      chart: {
        height: 350,
        type: 'line',
        toolbar: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      series: [
        {
          name: this.translate.instant('others.dailyUsers'),
          data: [],
        },
        {
          name: this.translate.instant('others.cumulativeUser'),
          data: [],
        },
      ],
      xaxis: {
        type: 'date',
        categories: [
          /*'2019-11-24T00:00:00',
          '2019-11-24T01:30:00',
          '2019-11-24T02:30:00'*/
        ],
      },
      yaxis: {
        type: 'numeric'
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy',
        },
        y: {
          formatter(val) {
            return val;
          },
          title: {
            formatter(seriesName) {
              return seriesName;
            }
          },
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
      },
    },
    {
      chart: {
        type: 'bar',
        height: 150,
        stacked: true,
        stackType: '100%',
        toolbar: false,
      },
      colors: ['#3e95cd', '#8e5ea2', '#3cba9f', '#e8c3b9', '#c45850'],
      series: [
        {
          name: '',
          data: [],
        },
        {
          name: '',
          data: [],
        },
      ],
      plotOptions: {
        bar: {
          horizontal: true,
        }
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: ['[%]'],
      },
      tooltip: {
        y: {
          formatter(val) {
            return val;
          },
          title: {
            formatter: (seriesName) => seriesName,
          },
        },
        x: {
          show: true,
          formatter() {
            return this.translate.instant('others.device');
          },
          title: {
            formatter: (seriesName) => seriesName + 'name',
          },
        }
      },
      fill: {
        opacity: 1,
        colors: ['#3e95cd', '#8e5ea2', '#3cba9f', '#e8c3b9', '#c45850']
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40,
      }
    }
  ];


  getDashboardData(): Observable<any> {
    return this.http.get<any>(this.dashboardUrl);
  }

  getData() {
    return iUserFilesCount;
  }

  getCharts() {
    return this.charts;
  }

  getStats() {
    return this.stats;
  }

  getStatsFromBackend() {
    this.getDashboardData().subscribe(answer => {
      this.statsFromBackEnd = answer.dashboardDataTiles;
    });
  }


}
