import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { RoutesRoutingModule } from './routes-routing.module';

import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './sessions/login/login.component';
import { RegisterComponent } from './sessions/register/register.component';
import { DevicesComponent } from './devices/devices.component';
import {NgApexchartsModule} from 'ng-apexcharts';

const COMPONENTS = [DashboardComponent, LoginComponent, RegisterComponent];
const COMPONENTS_DYNAMIC = [];

@NgModule({
  imports: [SharedModule, RoutesRoutingModule, NgApexchartsModule],
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC, DevicesComponent],
  entryComponents: COMPONENTS_DYNAMIC,
  exports: [
  ]
})
export class RoutesModule {}
