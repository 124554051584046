import { Component } from '@angular/core';

@Component({
  selector: 'app-user-panel',
  template: `
    <div class="matero-user-panel" fxLayout="column" fxLayoutAlign="center center">
      <img
        class="matero-user-panel-avatar"
        src="assets/images/avatarAdmin.png"
        alt="avatar"
        width="44"
      />
      <h4 class="matero-user-panel-name">Admin</h4>
<!--      <h5 class="matero-user-panel-email">admin@alosys.it</h5>-->
      <div class="matero-user-panel-icons">
<!--        <a routerLink="/profile/overview" mat-icon-button>-->
<!--          <mat-icon>account_circle</mat-icon>-->
<!--        </a>-->
<!--        <a routerLink="/profile/settings" mat-icon-button>-->
<!--          <mat-icon>settings</mat-icon>-->
<!--        </a>-->
        <a routerLink="/auth/login" mat-icon-button>
          <mat-icon title="{{ 'user.logout' | translate }}">exit_to_app</mat-icon>
        </a>
      </div>
    </div>
  `,
})
export class UserPanelComponent {}
