import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable()
export class ApiService {

  userUrl = 'localhost:80/api/users';

  constructor(private http: HttpClient, private router: Router) {
  }

  getUsers(): Observable<any> {
    return this.http.get<any>(this.userUrl);
  }
}
