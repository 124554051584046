import {environment} from '@env/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class DeviceService {

  private protocol = environment.protocol;
  private urlIpAddress = environment.SERVER_URL;
  private urlPort = environment.urlPort;
  private baseHref = environment.baseHref;
  url = this.protocol + '://' + this.urlIpAddress + ':' + this.urlPort;

  // deviceUrl = this.url + '/brdt/api/devices';

  deviceUrl = this.url + this.baseHref + 'devices';

  constructor(private http: HttpClient) {
  }

  getDevicesInUse(): Observable<any> {
    return this.http.get<any>(this.deviceUrl + '/in-use');
  }

  getDevices(): Observable<any> {
    return this.http.get<any>(this.deviceUrl);
  }
}
