import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {environment} from '@env/environment';

import {AdminLayoutComponent} from '../theme/admin-layout/admin-layout.component';
import {AuthLayoutComponent} from '../theme/auth-layout/auth-layout.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {LoginComponent} from './sessions/login/login.component';
import {RegisterComponent} from './sessions/register/register.component';
import {AuthGuard} from '@core';

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: { title: 'Dashboard', titleI18n: 'dashboard' },
      },
      {
        path: 'userAndGroup',
        loadChildren: () => import('./userAndGroup/userAndGroup.module').then(m => m.UserAndGroupModule),
        data: { title: 'User and Group Settings', titleI18n: 'userAndGroup' },
      },
      {
        path: 'logSettings',
        loadChildren: () => import('./logSettings/logSettings.module').then(m => m.LogSettingsModule),
        data: { title: 'Log Settings', titleI18n: 'material' },
      },
      // {
      //   path: 'helpers',
      //   loadChildren: () => import('./helpers/helpers.module').then(m => m.HelpersModule),
      // },
    ],
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent, data: { title: 'Login', titleI18n: 'login' } },
      {
        path: 'register',
        component: RegisterComponent,
        data: { title: 'Register', titleI18n: 'register' },
      },
    ],
  },
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
    }),
  ],
  exports: [RouterModule],
})
export class RoutesRoutingModule {}
